var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-wrap programme-water"},[_c('Header',{attrs:{"is-active":"programme","theme":"white"}}),_c('div',{staticClass:"empty-nav"}),_c('div',{staticClass:"sub-banner banner-water"}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"grey-wrap"},[_c('div',{staticClass:"max-center"},[_vm._m(5),_c('el-row',{attrs:{"gutter":"10"}},[_c('el-col',{staticStyle:{"margin-bottom":"6%"},attrs:{"xs":24,"sm":24,"md":10,"lg":10,"xl":10}},[_c('div',{staticClass:"case-box"},[_c('div',{staticClass:"case-item left-pic",on:{"click":function($event){return _vm.handleGo('/case-b')}}},[_c('div',{staticClass:"txt-bg"},[_vm._v("某水务集团智慧水务平台项目")])])])]),_c('el-col',{attrs:{"xs":24,"sm":24,"md":14,"lg":14,"xl":14}},[_c('div',{staticClass:"case-box"},[_c('div',{staticClass:"big-pic case-item",on:{"click":function($event){return _vm.handleGo('/case-a')}}},[_c('div',{staticClass:"txt-bg"},[_vm._v("某污水处理项目")])])])])],1)],1)]),_c('Footer'),_c('BackTop')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"white-wrap"},[_c('div',{staticClass:"txt-box align-ct no-indent pd-5"},[_c('p',[_vm._v("智慧水务基于先进的物联网技术、云计算大数据中心，依托涉水领域共享信息，构建基于数据中心的应用系统，")]),_c('p',[_vm._v("为涉水的政务业务、社会服务等领域提供智能化可靠解决方案，")]),_c('p',[_vm._v("以更加灵活高效、安全实时的方式支持水务运行的高复杂度系统工程。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grey-wrap"},[_c('div',{staticClass:"fz-big align-ct"},[_c('h4',[_vm._v("行业痛点")])]),_c('div',{staticClass:"pain-box"},[_c('div',{staticClass:"td-item"},[_c('img',{attrs:{"src":require("../../assets/img/programme/water/td_a.jpg"),"alt":"统一管理难度大"}}),_c('p',{staticClass:"color-59"},[_vm._v("统一管理难度大，厂区分布广泛，仪表设备数量，距离远。")])]),_c('div',{staticClass:"td-item"},[_c('img',{attrs:{"src":require("../../assets/img/programme/water/td_b.jpg"),"alt":"颗粒度不够"}}),_c('p',{staticClass:"color-59"},[_vm._v("颗粒度不够，很难准确精确提出绩效指标，监控数据不足。")])]),_c('div',{staticClass:"td-item"},[_c('img',{attrs:{"src":require("../../assets/img/programme/water/td_c.jpg"),"alt":"成本控制难"}}),_c('p',{staticClass:"color-59"},[_vm._v("成本控制难，跑冒滴漏，空载运行，高耗能点位监管缺失。")])]),_c('div',{staticClass:"td-item"},[_c('img',{attrs:{"src":require("../../assets/img/programme/water/td_d.jpg"),"alt":"数据价值丢失"}}),_c('p',{staticClass:"color-59"},[_vm._v("数据价值丢失，数字化技术和业务融合不足，数据挖掘少。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"white-wrap"},[_c('div',{staticClass:"max-center pd-5"},[_c('div',{staticClass:"fz-big align-ct"},[_c('h4',[_vm._v("云昇方案")])]),_c('div',{staticClass:"txt-box align-lt",staticStyle:{"padding-bottom":"1%"}},[_vm._v(" 结合水厂的日常巡检、PLC自动化设备控制、药剂管控、生产设备周期性维护、设备巡检、交接班、学习培训等生产管理工作，实现水厂生产过程管理的标准化和规范化，实现水厂业务全方位管控、信息汇集、资源共享、优化管理。确保水质安全、提升管理效率等方面的保障作用。 ")]),_c('div',{staticClass:"auto-img"},[_c('img',{staticClass:"mt-2",attrs:{"src":require("../../assets/img/programme/water/pic_a.png"),"alt":"云昇方案"}})])]),_c('div',{staticClass:"grey-wrap"},[_c('div',{staticClass:"max-center"},[_c('div',{staticClass:"fz-big align-ct"},[_c('h4',[_vm._v("方案场景")])]),_c('div',{staticClass:"auto-img"},[_c('img',{staticClass:"mt-2",attrs:{"src":require("../../assets/img/programme/water/pic_b.png"),"alt":"方案场景"}})])])]),_c('div',{staticClass:"white-wrap"},[_c('div',{staticClass:"max-center"},[_c('div',{staticClass:"fz-big align-ct"},[_c('h4',[_vm._v("业务功能")])]),_c('div',{staticClass:"auto-img"},[_c('img',{staticClass:"mt-2",attrs:{"src":require("../../assets/img/product/water/jg.png"),"alt":"业务功能"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grey-wrap"},[_c('div',{staticClass:"max-center"},[_c('div',{staticClass:"fz-big align-ct"},[_c('h4',[_vm._v("方案价值")])]),_c('div',{staticClass:"auto-img"},[_c('img',{staticClass:"mt-2",attrs:{"src":require("../../assets/img/programme/water/pic_c.png"),"alt":"方案价值"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"white-wrap"},[_c('div',{staticClass:"max-center pd-5"},[_c('div',{staticClass:"fz-big align-ct"},[_c('h4',[_vm._v("方案优势")])]),_c('div',{staticClass:"txt-box align-ct",staticStyle:{"padding-bottom":"1%"}},[_vm._v(" 云昇基于对客户需求的深入洞察，从调研规划、产品组合到交付运维，全面地为水务企业智慧水厂建设、运营、管理提供精准的解决方案，助力打造安全、高效、精益的智慧水厂，实现设计制造一体化、管控一体化，提高制水品质和管理效率。在此基础上，智慧水厂以安全可靠、节能降耗、智慧管理为建设目标，最终为百姓提供合格健康的水。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fz-big align-ct"},[_c('h4',[_vm._v("实际案例")])])
}]

export { render, staticRenderFns }