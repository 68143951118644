import { render, staticRenderFns } from "./Lighting.vue?vue&type=template&id=4db1c610&scoped=true"
import script from "./Lighting.vue?vue&type=script&lang=js"
export * from "./Lighting.vue?vue&type=script&lang=js"
import style0 from "./Lighting.vue?vue&type=style&index=0&id=4db1c610&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4db1c610",
  null
  
)

export default component.exports