<template>
  <div class="product-wrap service-message">
    <Header is-active="service" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-message"></div>
    <div class="white-wrap">
      <div class="max-center">
        <el-form ref="form" :rules="rules" :model="form" style="padding: 0 5%">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item>
                <el-input v-model="form.company" placeholder="公司名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item>
                <el-input v-model="form.trade" placeholder="所属行业"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item>
                <el-input v-model="form.area" placeholder="区域"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item prop="name">
                <el-input v-model="form.name" placeholder="姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item prop="tel">
                <el-input v-model="form.tel" placeholder="电话"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item>
                <el-input v-model="form.mail" placeholder="邮箱"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item prop="info">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" v-model="form.info"
                          placeholder="需求"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item>
                <el-button style="width:30%;" type="primary" @click="onSubmit">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '留言,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控留言-方案中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {
      form: {
        company: '',
        trade: '',
        area: '',
        name: '',
        tel: '',
        mail: '',
        info: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        tel: [{
          required: true,
          message: '请输入联系电话',
          trigger: 'blur'
        }],
        info: [{
          required: true,
          message: '请输入留言内容',
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.service-message {
  .banner-message {
    background: url("../../assets/img/service/banner1.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }
}

</style>
